import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf0ca630"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["transform"]
const _hoisted_3 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "formula-content",
    overflow: "visible",
    width: _ctx.box.w + 32,
    height: _ctx.box.h + 32,
    stroke: "#000",
    "stroke-width": "1",
    fill: "none",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, [
    _createElementVNode("g", {
      transform: `scale(${_ctx.scale}, ${_ctx.scale}) translate(0,0) matrix(1,0,0,1,0,0)`,
      "transform-origin": "0 50%"
    }, [
      _createElementVNode("path", { d: _ctx.pathd }, null, 8, _hoisted_3)
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}