import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "gradientTransform"]
const _hoisted_2 = ["stop-color"]
const _hoisted_3 = ["stop-color"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["stop-color"]
const _hoisted_6 = ["stop-color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'linear')
    ? (_openBlock(), _createElementBlock("linearGradient", {
        key: 0,
        id: _ctx.id,
        x1: "0%",
        y1: "0%",
        x2: "100%",
        y2: "0%",
        gradientTransform: `rotate(${_ctx.rotate},0.5,0.5)`
      }, [
        _createElementVNode("stop", {
          offset: "0%",
          "stop-color": _ctx.color1
        }, null, 8, _hoisted_2),
        _createElementVNode("stop", {
          offset: "100%",
          "stop-color": _ctx.color2
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("radialGradient", {
        key: 1,
        id: _ctx.id
      }, [
        _createElementVNode("stop", {
          offset: "0%",
          "stop-color": _ctx.color1
        }, null, 8, _hoisted_5),
        _createElementVNode("stop", {
          offset: "100%",
          "stop-color": _ctx.color2
        }, null, 8, _hoisted_6)
      ], 8, _hoisted_4))
}