import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "markerWidth", "markerHeight", "refX", "refY"]
const _hoisted_2 = ["d", "fill", "transform"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("marker", {
    id: `${_ctx.id}-${_ctx.type}-${_ctx.position}`,
    markerUnits: "userSpaceOnUse",
    orient: "auto",
    markerWidth: _ctx.size * 3,
    markerHeight: _ctx.size * 3,
    refX: _ctx.size * 1.5,
    refY: _ctx.size * 1.5
  }, [
    _createElementVNode("path", {
      d: _ctx.path,
      fill: _ctx.color,
      transform: `scale(${_ctx.size * 0.3}, ${_ctx.size * 0.3}) rotate(${_ctx.rotate}, 5, 5)`
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}