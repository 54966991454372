import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-741b366e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-pool" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailSlide = _resolveComponent("ThumbnailSlide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts, (slide) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "layout-item",
        key: slide.id,
        onClick: ($event: any) => (_ctx.selectSlideTemplate(slide))
      }, [
        _createVNode(_component_ThumbnailSlide, {
          class: "thumbnail",
          slide: slide,
          size: 180
        }, null, 8, ["slide"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}