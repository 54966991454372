import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64ca421e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-pool" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconChartLine = _resolveComponent("IconChartLine")!
  const _component_IconChartHistogram = _resolveComponent("IconChartHistogram")!
  const _component_IconChartPie = _resolveComponent("IconChartPie")!
  const _component_IconChartHistogramOne = _resolveComponent("IconChartHistogramOne")!
  const _component_IconChartLineArea = _resolveComponent("IconChartLineArea")!
  const _component_IconChartRing = _resolveComponent("IconChartRing")!
  const _component_IconChartScatter = _resolveComponent("IconChartScatter")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartList, (chart, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "chart-item",
        key: index
      }, [
        _createElementVNode("div", {
          class: "chart-content",
          onClick: ($event: any) => (_ctx.selectChart(chart))
        }, [
          (chart === 'line')
            ? (_openBlock(), _createBlock(_component_IconChartLine, {
                key: 0,
                size: "24"
              }))
            : (chart === 'bar')
              ? (_openBlock(), _createBlock(_component_IconChartHistogram, {
                  key: 1,
                  size: "24"
                }))
              : (chart === 'pie')
                ? (_openBlock(), _createBlock(_component_IconChartPie, {
                    key: 2,
                    size: "24"
                  }))
                : (chart === 'horizontalBar')
                  ? (_openBlock(), _createBlock(_component_IconChartHistogramOne, {
                      key: 3,
                      size: "24"
                    }))
                  : (chart === 'area')
                    ? (_openBlock(), _createBlock(_component_IconChartLineArea, {
                        key: 4,
                        size: "24"
                      }))
                    : (chart === 'ring')
                      ? (_openBlock(), _createBlock(_component_IconChartRing, {
                          key: 5,
                          size: "24"
                        }))
                      : (chart === 'scatter')
                        ? (_openBlock(), _createBlock(_component_IconChartScatter, {
                            key: 6,
                            size: "24"
                          }))
                        : _createCommentVNode("", true)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}