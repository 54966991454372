import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Screen = _resolveComponent("Screen")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_Mobile = _resolveComponent("Mobile")!

  return (_ctx.screening)
    ? (_openBlock(), _createBlock(_component_Screen, { key: 0 }))
    : (_ctx.isPC)
      ? (_openBlock(), _createBlock(_component_Editor, { key: 1 }))
      : (_openBlock(), _createBlock(_component_Mobile, { key: 2 }))
}