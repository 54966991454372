import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34c0a0a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["d", "stroke", "stroke-width", "stroke-dasharray"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.outline)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        class: "element-outline",
        overflow: "visible",
        width: _ctx.width,
        height: _ctx.height
      }, [
        _createElementVNode("path", {
          "vector-effect": "non-scaling-stroke",
          "stroke-linecap": "butt",
          "stroke-miterlimit": "8",
          fill: "transparent",
          d: `M0,0 L${_ctx.width},0 L${_ctx.width},${_ctx.height} L0,${_ctx.height} Z`,
          stroke: _ctx.outlineColor,
          "stroke-width": _ctx.outlineWidth,
          "stroke-dasharray": _ctx.outlineStyle === 'dashed' ? '10 6' : '0 0'
        }, null, 8, _hoisted_2)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}