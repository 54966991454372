import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "image-outline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageRectOutline = _resolveComponent("ImageRectOutline")!
  const _component_ImageEllipseOutline = _resolveComponent("ImageEllipseOutline")!
  const _component_ImagePolygonOutline = _resolveComponent("ImagePolygonOutline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.clipShape.type === 'rect')
      ? (_openBlock(), _createBlock(_component_ImageRectOutline, {
          key: 0,
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          radius: _ctx.clipShape.radius,
          outline: _ctx.elementInfo.outline
        }, null, 8, ["width", "height", "radius", "outline"]))
      : (_ctx.clipShape.type === 'ellipse')
        ? (_openBlock(), _createBlock(_component_ImageEllipseOutline, {
            key: 1,
            width: _ctx.elementInfo.width,
            height: _ctx.elementInfo.height,
            outline: _ctx.elementInfo.outline
          }, null, 8, ["width", "height", "outline"]))
        : (_ctx.clipShape.type === 'polygon')
          ? (_openBlock(), _createBlock(_component_ImagePolygonOutline, {
              key: 2,
              width: _ctx.elementInfo.width,
              height: _ctx.elementInfo.height,
              outline: _ctx.elementInfo.outline,
              createPath: _ctx.clipShape.createPath
            }, null, 8, ["width", "height", "outline", "createPath"]))
          : _createCommentVNode("", true)
  ]))
}