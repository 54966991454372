import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11e5b856"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide-thumbnails" }
const _hoisted_2 = { class: "return-button" }
const _hoisted_3 = { class: "slide-thumbnails-content" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowCircleLeft = _resolveComponent("IconArrowCircleLeft")!
  const _component_ThumbnailSlide = _resolveComponent("ThumbnailSlide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconArrowCircleLeft, {
        class: "icon",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["thumbnail", { 'active': index === _ctx.slideIndex }]),
          key: slide.id,
          onClick: ($event: any) => (_ctx.turnSlide(index))
        }, [
          _createVNode(_component_ThumbnailSlide, {
            slide: slide,
            size: 150,
            visible: index < _ctx.slidesLoadLimit
          }, null, 8, ["slide", "visible"])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}