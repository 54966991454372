import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridLines = _resolveComponent("GridLines")!

  return (_openBlock(), _createElementBlock("div", {
    class: "viewport-background",
    style: _normalizeStyle(_ctx.backgroundStyle)
  }, [
    (_ctx.showGridLines)
      ? (_openBlock(), _createBlock(_component_GridLines, { key: 0 }))
      : _createCommentVNode("", true)
  ], 4))
}